var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pricing-table is-comparative"},[_vm._m(0),_vm._l((_vm.planes),function(plan){return _c('div',{key:plan.id,class:[
            'pricing-plan',
            'has-ribbon',
            plan.classname,
            _vm.isSelectedPlan(plan) && 'active' ]},[(plan.descuento > 0)?_c('div',{staticClass:"ribbon is-info"},[_vm._v(" - "+_vm._s(_vm._f("percent")(plan.descuento))+" ")]):_vm._e(),_c('div',{staticClass:"plan-header"},[_vm._v(_vm._s(plan.descripcion))]),_c('div',{staticClass:"plan-price"},[(plan.precio > 0)?_c('span',{staticClass:"plan-price-amount"},[(plan.precio > 0)?_c('span',{staticClass:"plan-price-currency"},[_vm._v(" S/. ")]):_vm._e(),_vm._v(" "+_vm._s(plan.precio)+" ")]):_c('span',{staticClass:"plan-price-amount"},[_c('span',{staticClass:"plan-price-currency"}),_c('small',[_vm._v("Gratis")])])]),_c('div',{staticClass:"plan-items"},_vm._l((plan.caracteristicas),function(caracteristica){return _c('div',{key:caracteristica.id,staticClass:"plan-item"},[_vm._v(" "+_vm._s(caracteristica.valor_mostrable)+" ")])}),0),_c('div',{staticClass:"plan-footer"},[_c('button',{class:[
                    'button',
                    'is-fullwidth',
                    _vm.isSelectedPlan(plan) && 'is-primary' ],on:{"click":function($event){return _vm.choosePlan(plan)}}},[_vm._v(" "+_vm._s(_vm.isSelectedPlan(plan) ? 'Elegido' : 'Elegir')+" ")])])])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pricing-plan is-features"},[_c('div',{staticClass:"plan-header"},[_vm._v("Features")]),_c('div',{staticClass:"plan-price"},[_c('span',{staticClass:"plan-price-amount"},[_c('span',{staticClass:"plan-price-currency"},[_vm._v(" ")])])]),_c('div',{staticClass:"plan-items"},[_c('div',{staticClass:"plan-item"},[_vm._v("Cant. Trabajadores")]),_c('div',{staticClass:"plan-item"},[_vm._v("Cant. Documentos")]),_c('div',{staticClass:"plan-item"},[_vm._v("Reportes")]),_c('div',{staticClass:"plan-item"},[_vm._v("Notificaciones SMS")]),_c('div',{staticClass:"plan-item"},[_vm._v("Integración (REST API)")])]),_c('div',{staticClass:"plan-footer"})])}]

export { render, staticRenderFns }