<template>
    <div class="pricing-table is-comparative">
        <div class="pricing-plan is-features">
            <div class="plan-header">Features</div>
            <div class="plan-price">
                <span class="plan-price-amount">
                    <span class="plan-price-currency">&nbsp;</span>
                </span>
            </div>
            <div class="plan-items">
                <div class="plan-item">Cant. Trabajadores</div>
                <div class="plan-item">Cant. Documentos</div>
                <div class="plan-item">Reportes</div>
                <div class="plan-item">Notificaciones SMS</div>
                <div class="plan-item">Integración (REST API)</div>
            </div>
            <div class="plan-footer"></div>
        </div>

        <div
            v-for="plan of planes"
            :key="plan.id"
            :class="[
                'pricing-plan',
                'has-ribbon',
                plan.classname,
                isSelectedPlan(plan) && 'active',
            ]"
        >
            <div v-if="plan.descuento > 0" class="ribbon is-info">
                - {{ plan.descuento | percent }}
            </div>
            <div class="plan-header">{{ plan.descripcion }}</div>
            <div class="plan-price">
                <span v-if="plan.precio > 0" class="plan-price-amount">
                    <span v-if="plan.precio > 0" class="plan-price-currency">
                        S/.
                    </span>
                    {{ plan.precio }}
                </span>
                <span v-else class="plan-price-amount"
                    ><span class="plan-price-currency"></span
                    ><small>Gratis</small></span
                >
            </div>
            <div class="plan-items">
                <div
                    class="plan-item"
                    v-for="caracteristica of plan.caracteristicas"
                    :key="caracteristica.id"
                >
                    {{ caracteristica.valor_mostrable }}
                </div>
            </div>
            <div class="plan-footer">
                <button
                    :class="[
                        'button',
                        'is-fullwidth',
                        isSelectedPlan(plan) && 'is-primary',
                    ]"
                    @click="choosePlan(plan)"
                >
                    {{ isSelectedPlan(plan) ? 'Elegido' : 'Elegir' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrincingTable',
    data() {
        return {
            //
        };
    },
    computed: {
        planes() {
            return this.$store.state.planes.data;
        },
        selectedPlan() {
            return this.$store.state.planes.selected;
        }
    },
    mounted() {
        if (this.planes.length === 0) {
            this.fecthPlanes();
        }
    },
    methods: {
        async fecthPlanes() {
            await this.$store.dispatch('planes/get');
        },
        isSelectedPlan(plan) {
            return this.selectedPlan.id === plan.id;
        },
        choosePlan(plan) {
            this.$store.dispatch('planes/select', plan)
        }
    }
};
</script>

<style>
</style>
