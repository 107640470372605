<template>
    <div class="p-5">
        <div class="container">
            <h1 class="is-size-4 has-text-weight-medium mb-4">Elegir Plan</h1>
            <div class="is-relative">
                <b-loading v-model="loadingPlanes" :is-full-page="false" />
                <pricing-table />
            </div>
            <br />
            <b-button
                type="is-primary"
                tag="router-link"
                :to="{ name: 'account-registrar-empresa' }"
                :disabled="!selectedPlan.id"
                expanded
            >
                Continuar
            </b-button>
        </div>
    </div>
</template>

<script>
import PricingTable from '@/components/Account/PricingTable.vue';

export default {
    name: 'Planes',
    components: {
        PricingTable,
    },
    computed: {
        selectedPlan() {
            return this.$store.state.planes.selected;
        },
        loadingPlanes() {
            return this.$store.state.planes.isLoading;
        },
    }
}
</script>

<style>

</style>
